import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import { useSelector, useDispatch } from "react-redux";
import {
  sendInteractiveWhatsAppMessage,
  sendWhatsAppMessage,
  updateChat,
  updateChatStatusApi,
} from "../../slices/api/chatsAPI";
import StorageService from "../../services/storageService";
import { createCase as createCaseApi } from "../../slices/api/casesApi";
import {
  setActiveChat,
  setIdForChatCurrentlyAttending,
} from "../../slices/ChatSlice";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import moment from "moment";
import Constants from "../../constants/Constants";
import DropDown from "../DropDown";
import ConversationToDownLoadAsPdf from "../common/ConversationToDownloadAsPdf";
import { sendEmailApi } from "../../services/commonService";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../slices/ToastSlice";
import { getFileData } from "./ChatUtil";
import ImagePreviewPopup from "./ImagePreviewPopup";
import { getDepartmentById } from "../../slices/api/departmentsApi";
import { createSurvey } from "../../slices/api/surveyApi";

const options = [
  { label: "Email", value: "Email" },
  { label: "Convert to Case", value: "ConvertToCase" },
  { label: "Close Chat", value: "CloseChat" },
  // { label: "Download", value: "Download" },
  { label: "Block User", value: "BlockUser" },
];

const formatFileSize = (size) => {
  if (size < 1024) {
    return `${size} b`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} kb`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} mb`;
  }
};

const allowedTypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/pdf",
  "video/mp4",
  "video/mpeg",
  "text/plain",
  "application/json",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/javascript",
];

const getFileType = (extension) => {
  const documentExtensions = [
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "txt",
    "odt",
  ];
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg"];
  const videoExtensions = [
    "mp4",
    "mov",
    "wmv",
    "flv",
    "avi",
    "mkv",
    "webm",
    "mpeg",
  ];

  extension = extension.toLowerCase();

  if (documentExtensions.includes(extension)) {
    return "document";
  } else if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
};

const MessageView = () => {
  const userInfo = useSelector((state) => state.auth.user);
  const storageService = new StorageService();
  const downloadConversationAsPdfRef = useRef(null);
  const [chatDataToDownload, setChatDataToDownload] = useState(null);
  const [message, setMessage] = useState("");
  const chat = useSelector((state) => state.chat);
  const authUser = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [attachments, setAttachments] = useState([]);
  const [noValidFiles, setNotValidFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConvertingCase, setIsConvertingCase] = useState(false);
  const [chatToCase, setChatToCase] = useState(false);
  const [newChatEmail, setNewChatEmail] = useState();
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [reasonForBlocking, setReasonForBlocking] = useState("");
  const [showBlockUser, setShowBlockUser] = useState(false);

  useEffect(() => {
    setNewChatEmail(chat.activeChat?.email);
  }, [chat.activeChat]);

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendBtnClick = async (event) => {
    event.preventDefault();

    if (message.trim() === "") {
      return;
    }

    setIsSubmitting(true);

    const chatMetaData = {
      dateUpdated: new Date().toString(),
      message: {
        messageType: Constants.allowedIncomingMessages.TYPES.TEXT,
        messageContent: message,
        sender: Constants.messageSender.AGENT,
        dateCreated: new Date().toString(),
        isRead: false,
        attachmentIds: [],
      },
    };

    try {
      const uploadFiles = async () => {
        try {
          const uploadPromises = attachments.map(async (file) => {
            try {
              const uploadFileResult = await storageService.uploadFile(
                file,
                Constants.typeOfService.CHATS
              );
              if (!chatMetaData.message.attachmentIds) {
                chatMetaData.message.attachmentIds = [];
              }
              chatMetaData.message.attachmentIds.push(uploadFileResult);
            } catch (error) {
              throw new Error("An error occurred while uploading attachment.");
            }
          });

          await Promise.all(uploadPromises); // Wait for all uploadPromises to resolve
        } catch (error) {
          dispatch(
            setToastMessage("An error occurred while uploading attachment.")
          );
          dispatch(setToastTimer(3000));
          dispatch(setShowToast(true));
          console.log(error);
          setIsSubmitting(false);
          return false;
        }
      };

      await uploadFiles();

      updateChat(chat.activeChat.id, chatMetaData);
      setAttachments([]);
      setMessage("");

      if (chat.activeChat.channel === Constants.channels.WHATSAPP) {
        if (
          chatMetaData.message.attachmentIds &&
          chatMetaData.message.attachmentIds.length > 0
        ) {
          const sendAttachments = async () => {
            const promises = chatMetaData.message.attachmentIds.map(
              (attachment) => {
                const payload = getFileData(
                  chat.activeChat.whatsAppId,
                  getFileType(attachment.split(".").pop()),
                  `${Constants.STORAGE_BUCKET_URL}/${Constants.typeOfService.CHATS}/${attachment}`,
                  null
                );
                return sendInteractiveWhatsAppMessage(payload);
              }
            );

            await Promise.all(promises);
          };

          sendAttachments().catch((error) => {
            console.error("Error sending attachments:", error);
          });
        }

        sendWhatsAppMessage(
          chat.activeChat.whatsAppId,
          `*${userInfo.userInfo.givenName}*\n\n${message}`
        );
      }
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      dispatch(
        setToastMessage("An error occurred while trying to update send message")
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (chat.activeChat) {
      const updatedActiveChat = chat.conversations.find(
        (conversation) => conversation.id == chat.activeChat.id
      );

      if (updatedActiveChat)
        setChatDataToDownload({
          clientNumber:
            updatedActiveChat.channel == Constants.channels.WHATSAPP
              ? `+${updatedActiveChat.id}`
              : updatedActiveChat.Name,
          agentName: "Agent",
          chatList: updatedActiveChat.Messages,
        });

      dispatch(setActiveChat(updatedActiveChat));
    }
  }, [chat]);

  // Function to convert chat history to text part format
  function generateTextPartFormat(chatHistory) {
    let textPartFormat = "";
    chatHistory.messages.forEach((message, index) => {
      const { Sender, MessageContent, DateCreated } = message;
      textPartFormat += `${
        index + 1
      }. ${Sender} - ${MessageContent} (Sent: ${DateCreated})\n`;
    });
    return textPartFormat;
  }

  // Function to convert chat history to HTML part format
  function generateHtmlPartFormat(chatHistory) {
    let htmlPartFormat = "<div>\n";
    chatHistory.messages.forEach((message, index) => {
      const { sender, messageContent, dateCreated } = message;

      htmlPartFormat += `<div class="chat-message">\n`;
      htmlPartFormat += `<span class="chat-sender"><b>${sender}:</span></b> ${messageContent}\n`;
      htmlPartFormat += `<small><span class="chat-timestamp">${moment
        .utc(new Date(dateCreated), "MM/DD/YYYY h:mm A")
        .format("HH:mm DD MMM, YYYY")}</span></small><br>`;
      htmlPartFormat += `</div><br>`;
    });

    htmlPartFormat += "</div><br>";
    return htmlPartFormat;
  }

  const sendEmail = async (activeChat) => {
    const payload = {
      recipientEmailAddresses: activeChat.email,
      templateData: {
        caseNumber: "#mYSD-chat", // Replace with the case number you want to include in the subject
        chatDataInTextPartFormat: generateTextPartFormat(activeChat),
        chatDataInHtmlPartFormat: generateHtmlPartFormat(activeChat),
      },
      sourceEmail: Constants.sourceEmailList.NO_REPLY,
      templateName: "case_chat_history_v4",
    };

    try {
      const sendEmailResponse = await sendEmailApi(payload);
      console.log("Success: ", sendEmailResponse);
    } catch (error) {
      console.error("An error occurred while sending email: ", error);
    }
  };

  const closeChat = async () => {
    try {
      const chatMetaData = {
        dateUpdated: new Date().toString(),
        status: Constants.chatStages.CLOSED,
        closedBy: Constants.messageSender.AGENT,
        message: {
          messageType: Constants.allowedIncomingMessages.TYPES.TEXT,
          messageContent: `Your chat has been closed by: <b>${userInfo.userInfo.givenName}</b>`,
          sender: Constants.messageSender.AGENT,
          dateCreated: new Date().toString(),
          isRead: false,
        },
      };

      const userId =
        chat.activeChat.channel == Constants.channels.WHATSAPP
          ? chat.activeChat.whatsAppId
          : chat.activeChat.email;

      const surveyPayload = {
        dateCreated: new Date().toString(),
        dateUpdated: new Date().toString(),
        userId,
        identifier: chat.activeChat.id,
        agentId: chat.activeChat.agentId ? chat.activeChat.agentId : "",
      };

      const surveyId = await createSurvey(surveyPayload);
      const surveyLink = `https://support.mywsu.ac.za/survey?id=${surveyId}`;

      await updateChat(chat.activeChat.id, chatMetaData);
      dispatch(setIdForChatCurrentlyAttending(null));

      if (chat.activeChat.channel == Constants.channels.WHATSAPP) {
        const whatsAppMessage = `Your chat has been closed by: *${userInfo.userInfo.givenName}*`;
        await sendWhatsAppMessage(chat.activeChat.whatsAppId, whatsAppMessage);

        const surveyMessage = `Hi! We'd love your feedback. Could you take *1 minute* to fill out our *survey* and help us improve this offering? ${surveyLink}`;
        await sendWhatsAppMessage(chat.activeChat.whatsAppId, surveyMessage);
      } else {
        const templateDataForReporter = {
          userName: chat.activeChat.name,
          surveyLink: surveyLink,
        };

        const emailPayloadForReporter = {
          recipientEmailAddresses: [chat.activeChat.email],
          templateData: templateDataForReporter,
          sourceEmail: Constants.sourceEmailList.NO_REPLY,
          templateName: "survey_email",
        };

        await sendEmailApi(emailPayloadForReporter);
      }

      dispatch(
        setToastMessage("Successfully closed the chat and sent survey to user.")
      );
    } catch (e) {
      console.error("An error occurred while closing the chat:", e);
      dispatch(
        setToastMessage(
          "An error occurred while closing the chat. Please try again."
        )
      );
    } finally {
      dispatch(setToastTimer(4000));
      dispatch(setShowToast(true));
      dispatch(setActiveChat(null));
    }
  };

  const updateChatConvertedToCase = async () => {
    const whatsAppMessage = `Your chat has been converted to case by: *${userInfo.userInfo.givenName}*`;

    const chatMetaData = {
      dateUpdated: new Date().toString(),
      status: Constants.chatStages.ARCHIVED,
      closedBy: Constants.messageSender.AGENT,
      message: {
        messageType: Constants.allowedIncomingMessages.TYPES.TEXT,
        messageContent: whatsAppMessage,
        sender: Constants.messageSender.AGENT,
        dateCreated: new Date().toString(),
        isRead: false,
      },
    };

    await updateChat(chat.activeChat.id, chatMetaData);
    dispatch(setIdForChatCurrentlyAttending(null));
    if (chat.activeChat.channel == Constants.channels.WHATSAPP) {
      sendWhatsAppMessage(chat.activeChat.whatsAppId, whatsAppMessage);
    }
    dispatch(setActiveChat(null));
  };

  const emailChat = async () => {
    console.log(chat.activeChat);
    await sendEmail(chat.activeChat);
    alert("Emailed the Chat.");
  };

  const handleShowConfirmEmail = () => {
    setChatToCase(!chatToCase);
  };

  const handleEmailChange = (val) => {
    setNewChatEmail(val);
  };

  const convertToCase = async () => {
    if (!validateEmail(newChatEmail)) {
      dispatch(
        setToastMessage("Invalid email, please enter a valid email address.")
      );
      dispatch(setToastTimer(4000));
      dispatch(setShowToast(true));
      return;
    }

    setIsConvertingCase(true);
    setChatToCase(false);

    let impact = "individual";

    let newCaseData = {
      assignedTo: [],
      reportedBy: userInfo.userInfo.mail,
      priority: Constants.priorities.HIGH.Label,
      status: Constants.caseStatuses.OPEN,
      caseRequester: newChatEmail,
      department: chat.activeChat.category,
      caseSummary:
        chat.activeChat.messages.length > 0
          ? chat.activeChat.messages[0].messageContent
          : "Case opened from chat.",
      caseDescription:
        "Please read through the WhatsApp Chat attached on Origin Chat.",
      dateCreated: new Date().toString(),
      comments: [],
      originChatMessages: chat.activeChat.messages,
      impact,
      originChannel: Constants.channels.WEB_PORTAL,
      slaEscalated: false,
    };

    if (!!chat.activeChat.subCategory) {
      newCaseData.category = chat.activeChat.subCategory;
    }

    const theCaseData = { ...newCaseData };

    try {
      const createCaseResults = await createCaseApi(theCaseData);

      //start sending to reporter
      const templateDataForReporter = {
        caseNumber: createCaseResults.caseNumber,
        caseLink: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/cases/single_case/${createCaseResults.id}`,
        department: newCaseData.department,
        caseStatus: newCaseData.status,
        caseSummary: newCaseData.caseSummary,
        userName: "User",
      };

      const emailPayloadForReporter = {
        recipientEmailAddresses: [newChatEmail],
        templateData: templateDataForReporter,
        sourceEmail: Constants.sourceEmailList.NO_REPLY,
        templateName: "case_created_for_user_update24",
      };

      try {
        await sendEmailApi(emailPayloadForReporter);
      } catch (e) {
        console.log("Status - 500", e);
      }

      dispatch(
        setToastMessage(
          "Successfuly created the case from this chat, and we are now closing this chat."
        )
      );
      dispatch(setToastTimer(4000));
      dispatch(setShowToast(true));
      await updateChatConvertedToCase();
    } catch (error) {
      console.log(error);
      dispatch(setToastMessage("Failed to convert Case to Chat."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }

    setIsConvertingCase(false);
  };

  const reportChatUser = async () => {
    const reason = "User is rude."; //ToDo ask from user
    updateChatStatusApi(
      chat.activeChat.id,
      Constants.chatStages.BLOCKED,
      reason
    );

    const departmentDetails = await getDepartmentById(chat.activeChat.category);
    const payload = {
      recipientEmailAddresses: Object.keys(departmentDetails.admins),
      templateData: {
        identifier:
          chat.activeChat.channel == Constants.channels.WHATSAPP
            ? `+${chat.activeChat.id}`
            : chat.activeChat.name,
        channel: chat.activeChat.channel,
        reason: reason,
        blockerName: authUser.user.userInfo.mail,
      },
      sourceEmail: Constants.sourceEmailList.NO_REPLY,
      templateName: "user_blocked_admin_notifier_template_v2",
    };

    console.log(payload);
    await sendEmailApi(payload);
    alert("User blocked.");
    dispatch(setIdForChatCurrentlyAttending(null));
  };

  const handledAttendChatClick = async (event, chatId) => {
    event.preventDefault();

    try {
      const chatMetaData = {
        dateUpdated: new Date().toString(),
        agentId: userInfo.userInfo.mail,
        message: {
          messageType: Constants.allowedIncomingMessages.TYPES.TEXT,
          messageContent: `A customer support agent has joined you on the chat.`,
          sender: Constants.messageSender.AGENT,
          dateCreated: new Date().toString(),
          isRead: false,
        },
      };

      await updateChat(chatId, chatMetaData);
      if (chat.activeChat.channel == Constants.channels.WHATSAPP) {
        const whatsAppMessage = `You are now connected to agent, name: *${userInfo.userInfo.givenName}*`;
        sendWhatsAppMessage(chat.activeChat.whatsAppId, whatsAppMessage);
      }
      dispatch(setIdForChatCurrentlyAttending(chatId));
    } catch (exception) {
      alert("An error occured...");
    }
  };

  const checkIfChatIsOneAttended = () => {
    return (
      !chat.idForChatCurrentlyAttending ||
      chat.idForChatCurrentlyAttending !== chat.activeChat.id
    );
  };

  const loggedInUserAttendingChat = () => {
    const found = chat.conversations.find(
      (convo) =>
        convo.agentId == userInfo.userInfo.mail &&
        convo.status != Constants.chatStages.CLOSED
    );

    return found;
  };

  useEffect(() => {
    if (chat.activeChat && chat.activeChat.agentId) {
      dispatch(setIdForChatCurrentlyAttending(chat.activeChat.id));
    }
  }, [chat.activeChat]);

  const parseWhatsAppMessage = (message) => {
    if (message) {
      message = message.replace(/\n/g, "<br>");
      message = message.replace(/\*([^*]+)\*/g, "<b>$1</b>");
      message = message.replace(/_([^_]+)_/g, "<i>$1</i>");

      return message;
    }

    return null;
  };

  const getOptions = (chat) => {
    return options.filter((opt) => {
      return (
        opt.label !== "Close Chat" ||
        (chat.status !== Constants.chatStages.CLOSED &&
          loggedInUserAttendingChat() !== undefined)
      );
    });
  };

  const handleAttachmentsChange = (e) => {
    e.stopPropagation();
    let selectedFiles = e.target.files;
    let filesArray = Array.from(selectedFiles);
    let validFiles = [];
    let invalidFiles = [];

    filesArray.forEach((file) => {
      if (allowedTypes.includes(file.type)) {
        if (file.size <= 25 * 1024 * 1024) {
          validFiles.push(file);
        } else {
          console.log("Invalid file");
          invalidFiles.push(file);
        }
      } else {
        console.log("Invalid file size");
        invalidFiles.push(file);
      }
    });

    setAttachments(validFiles);
    setNotValidFiles(invalidFiles);
  };

  const imageFiles = attachments.filter(
    (file) =>
      file.type.startsWith("image/") || /\.(jpg|jpeg|png|gif)$/i.test(file.name)
  );

  const nonImagesFiles = attachments.filter(
    (file) => !imageFiles.includes(file)
  );

  const handleRemoveFile = (fileName) => {
    setAttachments((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const isUserCurrentlyAttendingChat = () => {
    return (
      chat.activeChat.status === Constants.chatStages.CHAT_OPENED &&
      userInfo.userInfo.mail === chat.activeChat.agentId
    );
  };

  const togglePopup = (imageID) => {
    setShowImagePopup(!showImagePopup);
    setSelectedImage(imageID);
  };

  const isImage = (filename) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg"];
    const ext = filename.split(".").pop().toLowerCase();
    return imageExtensions.includes(ext);
  };

  const handleShowBlockUser = () => {
    setShowBlockUser(!showBlockUser);
  };

  return (
    <>
      <div className="message-view">
        <div style={{ display: "none" }} id="myServiceDeskPdfTable"></div>
        <div className="inner-container">
          {chat && chat.activeChat ? (
            <>
              <div className="header-part">
                <h4>
                  {chat.activeChat.channel == Constants.channels.WHATSAPP
                    ? `+${chat.activeChat.whatsAppId}`
                    : chat.activeChat.name}{" "}
                  {chat.activeChat.email && (
                    <small style={{ color: "grey" }}>
                      ({chat.activeChat.email})
                    </small>
                  )}
                </h4>
                <div className="action-btn">
                  {chat.activeChat.status == Constants.chatStages.CHAT_OPENED &&
                  !chat.activeChat.agentId &&
                  !loggedInUserAttendingChat() ? (
                    <button
                      type="button"
                      className="attend-chat"
                      onClick={(event) =>
                        handledAttendChatClick(event, chat.activeChat.id)
                      }
                    >
                      Attend Chat
                    </button>
                  ) : (
                    <></>
                  )}
                  <DropDown
                    options={getOptions(chat.activeChat)}
                    closeChat={closeChat}
                    emailChat={emailChat}
                    convertToCase={handleShowConfirmEmail}
                    reportChatUser={handleShowBlockUser}
                  />
                </div>
              </div>
              {chatToCase && (
                <div className="convert-to-case-info shadow">
                  <h4>Convert Chat to Case</h4>
                  <div className="email">
                    <label htmlFor="client_email" className="required-field">
                      Confirm client email
                    </label>
                    <input
                      type="email"
                      name="client_email"
                      id="client_email"
                      value={newChatEmail}
                      onChange={(e) => setNewChatEmail(e.target.value)}
                      placeholder="Enter email"
                      required
                    />
                  </div>

                  <div className="action-btn">
                    <button
                      type="button"
                      className="main-btn"
                      disabled={isConvertingCase}
                      onClick={convertToCase}
                    >
                      {isConvertingCase ? "Converting..." : "Convert"}
                    </button>
                    <button
                      type="button"
                      className="no-border-btn"
                      disabled={isConvertingCase}
                      onClick={() => {
                        setChatToCase(!chatToCase);
                        setNewChatEmail(chat.activeChat?.email);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}

              {showBlockUser && (
                <div className="shadow block-user">
                  <h4>Block User</h4>
                  <p className="alert alert-warning">
                    Prevent disruptive or abusive users from contacting support,
                    ensuring a better environment for agents and customers.
                  </p>
                  <label htmlFor="blocking_reason">Reason for Blocking</label>
                  <input
                    type="text"
                    name="blocking_reason"
                    id="blocking_reason"
                    placeholder="Reason"
                    value={reasonForBlocking}
                    onChange={(e) => setReasonForBlocking(e.target.value)}
                    required
                  />
                  <div className="action-btn">
                    <button
                      type="button"
                      className="main-btn"
                      disabled={isConvertingCase}
                      onClick={reportChatUser}
                    >
                      {isConvertingCase ? "Converting..." : "Convert"}
                    </button>
                    <button
                      type="button"
                      className="no-border-btn"
                      disabled={isConvertingCase}
                      onClick={() => {
                        setShowBlockUser(!showBlockUser);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              <div className="messages">
                {chat.activeChat.messages.map((conversation) => {
                  return (
                    <div key={conversation.id}>
                      <div className="message-container">
                        <div
                          className={`message-wrapper ${
                            conversation.sender === "CLIENT"
                              ? "received"
                              : "outgoing"
                          }`}
                        >
                          <div className="message">
                            {conversation.attachmentIds?.length > 0 && (
                              <div className="image-files">
                                {conversation.attachmentIds.map((attachment) =>
                                  isImage(attachment) ? (
                                    <img
                                      key={attachment}
                                      src={`${Constants.STORAGE_BUCKET_URL}/${Constants.typeOfService.CHATS}/${attachment}`}
                                      alt={attachment}
                                      className="img-attachment"
                                      onClick={() => togglePopup(attachment)}
                                    />
                                  ) : (
                                    <a
                                      key={attachment}
                                      href={`${Constants.STORAGE_BUCKET_URL}/${Constants.typeOfService.CHATS}/${attachment}`}
                                      download
                                      className="file-download shadow"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {attachment}
                                    </a>
                                  )
                                )}
                              </div>
                            )}
                            
                            <div
                              dangerouslySetInnerHTML={{
                                __html: parseWhatsAppMessage(
                                  conversation.messageContent
                                ),
                              }}
                            />
                          </div>

                          <span className="time">
                            {moment
                              .utc(
                                new Date(conversation.dateCreated),
                                "MM/DD/YYYY h:mm A"
                              )
                              .add(2, "hours")
                              .format("HH:mm DD MMM, YYYY")}
                          </span>
                        </div>
                      </div>
                      {showImagePopup && (
                        <ImagePreviewPopup
                          selectedImage={selectedImage}
                          onClose={togglePopup}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {chat && chat.activeChat ? (
          <div className="write-message-container">
            {attachments.length !== 0 && (
              <div className="file-preview shadow-sm">
                {imageFiles.length !== 0 && (
                  <div className="imgs-preview-container">
                    {imageFiles.map((image, index) => (
                      <div key={index} className="img-container">
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Preview ${index + 1}`}
                          className="preview-image"
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveFile(image.name)}
                          className="remove-btn"
                        >
                          x
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                {imageFiles.length !== 0 && nonImagesFiles.length !== 0 && (
                  <div className="h-spacing"></div>
                )}

                {nonImagesFiles.length !== 0 && (
                  <div className="non-images-container">
                    {nonImagesFiles.map((file, index) => (
                      <div
                        key={index}
                        className="file-item shadow-sm"
                        download={URL.createObjectURL(file)}
                      >
                        <span className="file-name">{file.name}</span>
                        <span className="file-size">
                          {formatFileSize(file.size)}
                        </span>
                        <button
                          type="button"
                          onClick={() => handleRemoveFile(file.name)}
                          className="remove-btn"
                        >
                          x
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                {noValidFiles.length > 0 && (
                  <div className="not-valid-file bg-danger">
                    <span className="badge bg-dark">{noValidFiles.length}</span>{" "}
                    files are not valid.
                    <p>
                      These file are not included in the preview and will not be
                      sent.
                    </p>
                  </div>
                )}
              </div>
            )}
            {isUserCurrentlyAttendingChat() ? (
              <div className="write-message">
                <textarea
                  name="chat_message"
                  id="chat_message"
                  placeholder="Type Message..."
                  value={message}
                  onChange={handleInputChange}
                  disabled={checkIfChatIsOneAttended() || isSubmitting}
                ></textarea>
                <div className="action-btns">
                  <button type="button">
                    <EmojiEmotionsIcon />
                  </button>
                  <div className="attachments">
                    <label htmlFor="attachments">
                      <AttachFileIcon />
                    </label>
                    <input
                      type="file"
                      name="attachments"
                      id="attachments"
                      onChange={handleAttachmentsChange}
                      hidden
                      disabled={checkIfChatIsOneAttended() || isSubmitting}
                      multiple={true}
                    />
                  </div>
                  <span className="v-divider"></span>
                  <button
                    type="button"
                    className="send-btn"
                    disabled={checkIfChatIsOneAttended() || isSubmitting}
                    onClick={handleSendBtnClick}
                  >
                    {isSubmitting ? "Submitting..." : <SendIcon />}
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MessageView;
